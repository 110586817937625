<template>
  <div class="event" :key="rerender">
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'event',
  data() {
    return {
      rerender: 0
    }
  },
  async beforeCreate() {
    await this.$store.getEvent(this.$route.params.id)
    this.rerender++
    document.title = this.$store.state.event.name
  }
}
</script>

<style>


</style>
